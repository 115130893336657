
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      userProductsIds: [],
      login: false,
      showAddress: false,
      showCouponForm: false,
      // register: false,
      loopProduct: {},
      addToCartProduct: {},
      intro: true,
      liveConsultation: false,
    }
  },
  watch: {
    addToCartProduct() {
      this.$cart.addItem(this.addToCartProduct, 1)
    },
    // register() {
    //   this.sendDummyAddress()
    // },
  },
  async mounted() {
    await this.$nextTick()
    // this.skipAddress()
  },
  computed: {
    ...mapState(['orders']),
  },
  beforeDestroy() {
    // this.emptyCart()
    document.body.style.overflow = ''
  },

  methods: {
    ...mapActions(['setOrders']),
    checkConsultation(product) {
      let liveConsultation = false
      if (product.sku.startsWith('live-consultations')) {
        this.liveConsultation = true
        liveConsultation = true
      }
      return liveConsultation
    },
    // sendDummyAddress() {
    //   setTimeout(() => {
    //     const registerForm = this.$refs.register.form.deliveryForm
    //     registerForm.city = 'city'
    //     registerForm.country = 'GB'
    //     registerForm.houseNumber = '1'
    //     registerForm.street = 'street'
    //     registerForm.zipCode = '12345'
    //   }, 1000)
    // },
    emptyCart() {
      if (!this.$refs.cart) return
      for (let i = 0; i < this.$refs.cart.cartItems.length; i++) {
        this.$refs.cart.deleteCartItem(this.$refs.cart.cartItems[i])
      }
    },
    getProductIds() {
      if (this.orders && this.orders.length) {
        const userProductsIds = this.orders
        this.userProductsIds = [...new Set(userProductsIds)]
      }
    },
    // async getSingleProduct(id, item) {
    //   const data = await this.$api.product.web.get({
    //     id,
    //     storeId: this.$application.store.storeID,
    //     currency: this.$application.currency.label,
    //   })
    //   if (data) {
    //     if (item === 'loop') {
    //       this.loopProduct = data.data
    //     } else if (item === 'cart') {
    //       this.addToCartProduct = data.data
    //     }
    //   }
    // },
    // checkBundle(bundles) {
    //   let state = true
    //   bundles.forEach((product) => {
    //     if (this.userProductsIds.includes(product.product.id)) {
    //       state = false
    //     }
    //   })
    //   return state
    // },
  },
}
