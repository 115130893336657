import { render, staticRenderFns } from "./CheckoutView.vue?vue&type=template&id=74a1778a"
import script from "./CheckoutView.vue?vue&type=script&lang=js"
export * from "./CheckoutView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerCart: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerCart.js').default,UiInput: require('/home/node/app/components/ui/uiInput.vue').default,Btn: require('/home/node/app/components/ui/btn.vue').default,ControllerAddress: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerAddress.js').default,ControllerPayment: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerPayment.js').default})
