
import _ from 'lodash'
import ControllerPostCategoryList from 'Controllers/ControllerPostCategoryList'
import ControllerPostList from 'Controllers/ControllerPostList'
export default {
  components: {
    ControllerPostList,
    ControllerPostCategoryList,
  },
  data() {
    return {
      categories: [],
      posts: [],
      searchPhrase: '',
      options: {
        page: 1,
      },
    }
  },
  mounted() {
    this.setPosts()
    this.$watch(
      () => {
        return this.$refs.postList.items
      },
      (val) => {
        this.setPosts()
      }
    )
  },
  methods: {
    setPosts() {
      this.posts = this.$refs.postList.items
    },
    searchPosts: _.debounce(function () {
      if (this.searchPhrase.length === 0) {
        this.setPosts()
        return
      }
      this.posts = this.$refs.postList.items.filter((post) => {
        return post.title.indexOf(this.searchPhrase.toLowerCase()) ? post.title.includes(this.searchPhrase.toLowerCase()) : this.$refs.postList.items
      })
    }, 500),
    appendCategory(category) {
      if (this.categories.includes(category.slug)) return
      this.categories.push(category.slug)
    },
  },
}
