
export default {
  props: {
    selectClass: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Number],
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    labelFor: {
      type: String,
      required: false,
    },
    errorMessage: {
      type: [String, Boolean],
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
}
