
import _ from 'lodash'
import { mapState, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
// import { relativeTimeRounding } from 'moment'
// import VuelidateHelper from '~/.nuxt/gb-cms/mixins/VuelidateHelper'
import { validationMixin } from 'vuelidate'

export default {
  data() {
    return {
      activeLink: 'exams',
      viewResult: false,
      userProductsIds: [],
      products: [],
      sd: 40,
      passwordEdited: false,
      init: true,
      errors: false,
      examInfo: false,
      form: {
        firstName: '',
        lastName: '',
        day: '',
        gender: '',
        month: '',
        year: '',
        currentSchool: '',
        targetSchool1: '',
        targetSchool2: '',
      },
      examsByProducts: [],
      productBundles: [],
      modalInfo: false,
      modalExam: {},
      age: null,
      finished: false,
      average: 100,
      loading: true,
      examChecked: false,
      activeProductIndex: null,
      activeProductInvigilated: false,
      productExams: [],
      userExam: [],
    }
  },
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      day: {
        required,
      },
      gender: {
        required,
      },
      month: {
        required,
      },
      year: {
        required,
      },
      currentSchool: {
        required,
      },
      targetSchool1: {
        required,
      },
    },
  },
  watch: {
    orders() {
      if (this.$auth.loggedIn) {
        this.getProductIds()
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },
    activeLink(val) {
      if (val !== 'productExams') {
        this.activeProductInvigilated = false
      }
      this.$router.push({
        query: { activeLink: val },
      })
    },
    activeProductIndex() {
      if (this.checkUserExams(this.examsByProducts[this.activeProductIndex]) === 'completed') {
        this.finished = true
      }
    },
    'form.day'() {
      this.ageCalculator()
    },
    'form.month'() {
      this.ageCalculator()
    },
    'form.year'() {
      this.ageCalculator()
    },
  },
  mounted() {
    this.setOrders()
    if (this.$route && this.$route.query && this.$route.query.activeLink && this.activeProductIndex) {
      this.activeLink = this.$route.query.activeLink
    }
    setTimeout(() => {
      this.viewResult = true
    }, 2000)
  },
  beforeDestroy() {
    document.body.style.overflow = ''
  },
  computed: {
    ...mapState(['orders']),
    orderedProducts() {
      return _.orderBy(this.products, 'name')
    },
    passwordForm() {
      let oldPassword = null
      if (this.$refs.passwordForm) {
        oldPassword = this.$refs.passwordForm
      }
      return oldPassword
    },
  },
  mixins: [validationMixin],
  //   mixins: [
  //     VuelidateHelper({
  //       firstName: '',
  //       lastName: '',
  //       gender: '',
  //       day: '',
  //       month: '',
  //       year: '',
  //       currentSchool: '',
  //       targetSchool1: '',
  //     }),
  //   ],
  methods: {
    ...mapActions(['setOrders']),
    // setOrders method iz storea (povlači kupljene proizvode) i preko watcha zove getProductIds

    ageCalculator() {
      // izračunava djetetove godine prema datumu rođenja unesenom u modal formu.
      const today = new Date()
      const birthDate = new Date(this.form.year, this.form.month - 1, this.form.day, 0, 0, 0, 0)
      this.age = (today.getFullYear() - birthDate.getFullYear()) * 12 + (today.getMonth() - birthDate.getMonth())
    },
    logOut() {
      this.$auth.logout()
      if (!this.$auth.loggedIn) {
        this.$toast.success('Logout successful')
      }
    },
    createModal(exam) {
      // modal koji se otvara prilikom pokretanja ispita. Ako je dijete već unijelo podatke  na jednom ispitu, forma se ne prikazuje, nego se podaci unešeni na jednom ispitu unose i na svaki sljedeći ispit.
      this.modalExam = exam
      for (let i = 0; i < this.examsByProducts[this.activeProductIndex].length; i++) {
        const exam = this.examsByProducts[this.activeProductIndex][i]
        if (exam.userExam && exam.userExam.additionalOptions) {
          const options = []
          if (exam.userExam.additionalOptions.firstName) {
            this.form.firstName = exam.userExam.additionalOptions.firstName
            options.push(true)
          }
          if (exam.userExam.additionalOptions.lastName) {
            this.form.lastName = exam.userExam.additionalOptions.lastName
            options.push(true)
          }
          if (exam.userExam.additionalOptions.currentSchool) {
            this.form.currentSchool = exam.userExam.additionalOptions.currentSchool
            options.push(true)
          }
          if (exam.userExam.additionalOptions.targetSchool1) {
            this.form.targetSchool1 = exam.userExam.additionalOptions.targetSchool1
            options.push(true)
          }
          if (exam.userExam.additionalOptions.targetSchool2) {
            this.form.targetSchool2 = exam.userExam.additionalOptions.targetSchool2
            options.push(true)
          }
          if (exam.userExam.additionalOptions.gender) {
            this.form.gender = exam.userExam.additionalOptions.gender
            options.push(true)
          }
          if (exam.userExam.additionalOptions.day) {
            this.form.day = exam.userExam.additionalOptions.day
            options.push(true)
          }
          if (exam.userExam.additionalOptions.month) {
            this.form.month = exam.userExam.additionalOptions.month
            options.push(true)
          }
          if (exam.userExam.additionalOptions.year) {
            this.form.year = exam.userExam.additionalOptions.year
            options.push(true)
          }
          if (options.length > 6) {
            this.examInfo = true
          }
        }
      }
      if (this.examInfo) {
        this.modalInfo = true
        this.ageCalculator()
      }
      this.$modal.show('create')
    },
    hideModal() {
      this.$modal.hide('create')
      this.modalInfo = false
    },
    checkFormErrors() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.modalInfo = true
      } else {
        return this.$v.$anyError
      }
    },
    async createExam() {
      // u slučaju da za kupljeni exam nije kreiran user exam, metoda kreira exam. Prilikom pokretanja exama otvara se modal sa formom za upis osobnih podataka. ovi podaci se mogu razlikovati od podataka koji su unešeni prilikom registracije, pošto je kupac roditelj, a exam riješava dijete. Podaci unešeni u ovu formu spremaju se pod additionalOptions key. Ovaj key inače koristimo za sve custom stvari koje treba spremiti u bazu.
      if (this.$auth.loggedIn) {
        try {
          const body = {
            title: this.modalExam.title,
            allowContinue: true,
            allowBacktracking: false,
            currentTimer: this.modalExam.timeLimit,
            isGuest: false,
            webuser_id: this.$auth.user.id,
            exam_id: this.modalExam.id,
            additionalOptions: {
              invigilated: this.activeProductInvigilated,
              product: this.orderedProducts[this.activeProductIndex].name,
              age: this.age,
              gender: this.form.gender,
              day: this.form.day,
              month: this.form.month,
              year: this.form.year,
              firstName: this.form.firstName,
              lastName: this.form.lastName,
              currentSchool: this.form.currentSchool,
              targetSchool1: this.form.targetSchool1,
              targetSchool2: this.form.targetSchool2,
            },
            status: 'in-progress',
          }

          const data = await this.$axios.post('api/v1/core/e-learning/user-exams/createWeb', body)
          if (data) {
            this.modalExam.userExam = data.data.data
            this.examLink(this.modalExam.id)
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    examLink(id) {
      const md5 = require('md5')
      const hash = md5(this.$auth.user.id + this.orders)
      this.$router.push({
        path: '/exams/' + id,
        query: { hash },
      })
    },
    async getExams(examsIds) {
      // metoda prvo povlači kupljene examove, a onda prema njihovim ID-evima i userExamove
      let exams = []
      const body = {
        filters: [
          {
            key: 'id',
            value: examsIds,
          },
        ],
      }

      try {
        const data = await this.$axios.post('api/v1/core/e-learning/exams/filter', body)
        if (data) {
          if (data.data.data && data.data.data.data && data.data.data.data.length) {
            exams = data.data.data.data
            const userExamRequests = []
            const userExamIds = []
            for (let i = 0; i < exams.length; i++) {
              userExamIds.push(exams[i].id)
            }
            userExamRequests.push(this.getUserExam(userExamIds))
            try {
              const userExamResponses = await Promise.all(userExamRequests)
              if (userExamResponses[0] && userExamResponses[0].length) {
                const ue = exams.map((e, i) => {
                  const temp = userExamResponses[0].find((element) => element.exam_id === e.id)
                  if (temp) {
                    e.userExam = temp
                  }
                  return e
                })
                return ue
              }
            } catch (error) {
              console.log(error)
            }
            return exams
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    checkUserExams(exams) {
      // metoda vraća Exam status
      const examState = []
      let state = ''
      if (exams && exams.length) {
        for (let i = 0; i < exams.length; i++) {
          if (exams[i].userExam && exams[i].userExam.status === 'closed') {
            examState.push(true)
          } else {
            examState.push(false)
          }
        }
        if (examState.includes(true) && examState.includes(false)) {
          state = 'partiallyCompleted'
        } else if (examState.includes(true)) {
          state = 'completed'
        } else {
          state = 'notStarted'
        }
        this.examChecked = true
      }
      return state
    },

    async getUserExam(id) {
      // metoda povlači user examove prema exam ID-u
      const body = {
        filters: [
          {
            key: 'archived',
            value: false,
          },
          {
            key: 'exam_id',
            value: id,
          },
          {
            key: 'webuser_id',
            value: this.$auth.user.id,
          },
        ],
      }

      try {
        const data = await this.$axios.post('api/v1/core/e-learning/user-exams/filterWebApto', body)
        if (data) {
          if (data.data.data.data.length) {
            return data.data.data.data
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    getEncodedExamIds(exams, packageName, path) {
      // metoda koja encodira exam id-eve za potrebe kreiranja link query parametara
      const examIds = []
      for (let i = 0; i < exams.length; i++) {
        examIds.push(exams[i].userExam.id)
      }
      const encIds = encodeURIComponent(JSON.stringify(examIds))
      this.$router.push({
        path,
        query: { exams: encIds, package: packageName },
      })
    },
    getProductIds(ids) {
      if (this.orders && this.orders.length) {
        this.userProductsIds = this.orders
        const uniqueItems = [...new Set(this.userProductsIds)]
        this.getUserProducts(uniqueItems)
        this.loading = false
      } else {
        this.init = false
      }
    },
    async getUserProducts(ids) {
      // metoda dovlači produkte i zapisuje u the.products array. Ako je produkt bundle, opet poziva smau sebe.
      try {
        const body = {
          page: 1,
          limit: 88,
          filters: [
            {
              key: 'archived',
              value: false,
              default: true,
            },
            {
              key: 'id',
              value: ids,
            },
          ],
          list: false,

          select: ['name', 'id', 'attributeSet', 'crossSellProducts', 'customOptions', 'bundles', 'sku', 'description', 'format'],
        }
        const res = await this.$axios.post('/api/v1/product/web/filter', body)
        if (res && res.data.data) {
          const products = res.data.data.records
          // console.log(products)
          for (let i = 0; i < products.length; i++) {
            if (products[i].format === 'eProduct') {
              this.products.push(products[i])
            } else {
              this.productBundles.push(products[i])
              const bundleProducts = []
              products[i].bundles.forEach((simple) => {
                if (!this.userProductsIds.includes(simple.product.id)) {
                  bundleProducts.push(simple.product.id)
                }
              })
              this.getUserProducts(bundleProducts)
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
      // produkti u custom optionsima sadrže Examove. za svaki Exam Id se poziva getExams metoda
      const promiseArray = []
      for (let i = 0; i < this.orderedProducts.length; i++) {
        if (this.orderedProducts[i].customOptions && this.orderedProducts[i].customOptions.length) {
          console.log(this.orderedProducts)
          promiseArray.push(this.getExams(this.orderedProducts[i].customOptions[0].option_value[0].value))
        }
      }
      try {
        const values = await Promise.all(promiseArray)
        if (values.length) {
          this.examsByProducts = values
        }
      } catch (error) {
        console.log(error)
      }

      if (this.$route.query && this.$route.query.exam) {
        // kad se vraća sa exam rute na rezultate, route query može sadržavat exam id. U tom slučaju taj ispit je prikazan na profilu
        if (this.orderedProducts.length) {
          for (let i = 0; i < this.orderedProducts.length; i++) {
            if (this.examsByProducts[i] && this.examsByProducts[i].length) {
              this.examsByProducts[i].forEach((exam) => {
                if (exam.userExam && exam.userExam.id === parseInt(this.$route.query.exam)) this.activeProduct(i, this.userProductsIds.includes(this.orderedProducts[i].crossSellProducts[0]))
              })
            }
          }
        }
      }
    },
    activeProduct(index, invigilated) {
      // set active Product (Vidljiv je single produkt sa pripadajućim Examovima u njemu)
      this.activeProductInvigilated = invigilated
      this.activeProductIndex = index
      this.activeLink = 'productExams'
      document.body.style.overflow = 'hidden'
    },
    change() {
      this.$toast.success('Changes saved successfully!')
      //   location.reload()
    },
  },
}
