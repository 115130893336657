
import ControllerPostList from 'Controllers/ControllerPostList'
export default {
  components: {
    ControllerPostList,
  },
  data() {
    return {
      categories: [],
      options: {
        page: 2,
      },
    }
  },
  methods: {
    appendCategory(category) {
      if (this.categories.includes(category.slug)) return
      this.categories.push(category.slug)
    },
  },
}
