import Vue from 'vue'
import moment from 'moment'
import _ from 'lodash'

Vue.mixin({
  methods: {
    $formatMonth(date) {
      return moment(date).format('DD.MM')
    },
    $momentFormat(date, format) {
      return moment(date).format(format)
    },
    $formatHour(date) {
      return moment(date).format('HH')
    },
    $formatDate(post) {
      if (post.published_at) return moment(post.published_at).format('DD MMM YYYY')

      return moment(post.created_at).format('DD MMM YYYY')
    },

    // Check NEW tag
    $checkNewTag(product) {
      if (product.tags) {
        for (let i = 0; i < product.tags.length; i++) {
          if (product.tags[i].name === 'NOVO') {
            return true
          }
        }
      }
    },
    $getAbbreviation(str) {
      let abbr = ''
      if (str.split(' ').length > 1) {
        abbr = str.match(/[A-Z]/g).join('')
      } else {
        abbr = str
      }
      return abbr
    },

    $returnDiscountPercent(product) {
      /* eslint-disable */
      if (product.hasOwnProperty('discount')) {
        const oldPrice = product.retailPrice
        const newPrice = product.discount.retailPrice
        const calculatePrice = (oldPrice - newPrice) / oldPrice
        const calculatePercent = calculatePrice * 100
        return calculatePercent.toFixed()
      } else return false
      /* eslint-enable */
    },
    $checkTag(product, tag) {
      if (product.tags) {
        for (let i = 0; i < product.tags.length; i++) {
          if (product.tags[i].name === tag) {
            return true
          }
        }
      }
    },
    // Check NEW tag
    $checkTopTag(product) {
      if (product.tags) {
        for (let i = 0; i < product.tags.length; i++) {
          if (product.tags[i].name === 'NAJPRODAVANIJE') {
            return true
          }
        }
      }
    },

    $shareUrl() {
      if (process.browser) {
        return window.location.href
      }
    },

    $getDM(questions) {
      let correctAnswersScore = 0
      const multiAnswers = _.groupBy(questions, 'exam_question_id')
      Object.keys(multiAnswers).forEach((question, index) => {
        const questionData = JSON.parse(multiAnswers[question][0].exam_question_data)
        if (questionData.type === 'single' && multiAnswers[question][0].isTrue) {
          const rank = questionData.rank
          correctAnswersScore = correctAnswersScore + rank
        } else if (questionData.type === 'multi') {
          let status = false
          const trueFalse = _.groupBy(questionData.answers, 'isTrue')
          if (multiAnswers[question] && multiAnswers[question].length === trueFalse[1].length) {
            for (let i = 0; i < multiAnswers[question].length; i++) {
              if (multiAnswers[question][i].isTrue) {
                status = true
              } else {
                status = false
                break
              }
            }
            if (status === true) {
              const rank = questionData.rank
              correctAnswersScore = correctAnswersScore + rank
            }
          }
        }
      })
      return correctAnswersScore
    },
    $getADM(exam) {
      const correctAnswersScore = this.$getDM(exam.answers)
      //   const adm = correctAnswersScore * (1 + (144 - parseInt(exam.additionalOptions.age)) * exam.additionalOptions.impact)
      const adm = correctAnswersScore + (144 - parseInt(exam.additionalOptions.age)) * exam.additionalOptions.impact * exam.additionalOptions.sd
      return adm.toFixed()
    },
    $getSAS(exam) {
      const sas = (15 * (this.$getADM(exam, exam.additionalOptions.impact) - exam.additionalOptions.average)) / exam.additionalOptions.sd + 100
      const fixed = sas.toFixed()
      return fixed
    },
    $returnOrderedSections(examParam) {
      let exam = {}
      //   let orderedSections = []
      if (typeof examParam === 'object') {
        exam = examParam
      } else {
        exam = JSON.parse(examParam)
      }
      examParam.sections = _.orderBy(exam.sections, 'sku')
      //   orderedSections = _.orderBy(exam.sections, 'sku')
      return examParam
    },

    $answersBySections(answers) {
      // Grupe
      const groupedTopics = [
        {
          title: 'Verbal Reasoning',
          groups: [
            {
              groupTitle: 'Reasoning and logic',
              groupId: 9999999999,
              group: ['Topic 1', 'Topic 3', 'Topic 5'],
              noq: 0,
            },
            {
              groupTitle: 'Spelling and vocabulary',
              groupId: 9999999998,
              group: ['Topic 2', 'Topic 4', 'Topic 6'],
              noq: 0,
            },
          ],
        },
        {
          title: 'NVR',
          groups: [
            {
              groupTitle: 'Inductive reasoning',
              groupId: 9999999997,
              group: ['Topic 1', 'Topic 4'],
              noq: 0,
            },
            {
              groupTitle: 'Deductive reasoning',
              groupId: 9999999996,
              group: ['Topic 2', 'Topic 5'],
              noq: 0,
            },
            {
              groupTitle: 'Spatial reasoning',
              groupId: 9999999995,
              group: ['Topic 3', 'Topic 6'],
              noq: 0,
            },
          ],
        },

        {
          title: 'Non-verbal Reasoning',
          groups: [
            {
              groupTitle: 'Inductive reasoning',
              groupId: 9999999997,
              group: ['Topic 1', 'Topic 4'],
              noq: 0,
            },
            {
              groupTitle: 'Deductive reasoning',
              groupId: 9999999996,
              group: ['Topic 2', 'Topic 5'],
              noq: 0,
            },
            {
              groupTitle: 'Spatial reasoning',
              groupId: 9999999995,
              group: ['Topic 3', 'Topic 6'],
              noq: 0,
            },
          ],
        },
      ]

      const answersSectionsId = answers
      answersSectionsId.forEach((exm, index) => {
        groupedTopics.forEach((group) => {
          if (group.title === exm.title) {
            // start answers
            exm.answers.forEach((obj) => {
              const questionData = JSON.parse(obj.exam_question_data)
              const sectionId = questionData.section_id
              // const sectionTitle = this.getSectionData(index, sectionId).title
              const sectionTitle = this.getSectionData(index, sectionId).sku

              group.groups.forEach((groupData) => {
                if (groupData.group.includes(sectionTitle)) {
                  questionData.section_id = groupData.groupId
                  obj.exam_question_data = JSON.stringify(questionData)
                }
              })
            })
            // end answers
            // start section
            const examData = this.$returnOrderedSections(JSON.parse(exm.exam_data))
            const sections = examData.sections

            sections.forEach((section) => {
              group.groups.forEach((groupData) => {
                // if (groupData.group.includes(section.title)) {
                if (groupData.group.includes(section.sku)) {
                  section.id = groupData.groupId
                  section.title = groupData.groupTitle
                  groupData.noq = groupData.noq + section.number_of_questions
                  section.noq = groupData.noq
                }
              })
              const unique = [...new Map(sections.map((item) => [item.id, item])).values()]

              examData.sections = unique
              exm.exam_data = JSON.stringify(examData)
            })

            // end section
          }
        })
        // end groups
        exm.answers.forEach((obj) => {
          obj.section_id = JSON.parse(obj.exam_question_data).section_id
          // exm.answers.push(obj)
        })
        const groupedAnswers = _.groupBy(exm.answers, 'section_id')
        const groupedAnswersMap = this.$returnOrderedSections(JSON.parse(exm.exam_data)).sections.map((x) => x.id)
        const tempGroupedAnswers = {}
        for (let i = 0; i < groupedAnswersMap.length; i++) {
          this.$set(tempGroupedAnswers, (i + 1).toString() + groupedAnswersMap[i].toString(), groupedAnswers[groupedAnswersMap[i]])
        }
        answersSectionsId[index].tempGroupedAnswers = []
        answersSectionsId[index].groupedAnswers = []
        answersSectionsId[index].groupedAnswers.push(groupedAnswers)
        answersSectionsId[index].tempGroupedAnswers.push(tempGroupedAnswers)
      })
      return answersSectionsId
    },
    $noScroll() {
      document.body.style.overflow = 'hidden'
    },
    $scroll() {
      document.body.style.overflow = ''
    },
  },
})
