
export default {
  mounted() {
    this.sendDummyAddress()
  },
  methods: {
    sendDummyAddress() {
      setTimeout(() => {
        const registerForm = this.$refs.register.form.deliveryForm
        registerForm.city = 'city'
        registerForm.contactPhone = '123456'
        registerForm.country = 'GB'
        registerForm.houseNumber = '1'
        registerForm.street = 'street'
        registerForm.zipCode = '12345'
      }, 1500)
    },
    formSubmit(submit, form) {
      form.deliveryForm.contactPhone = form.acceptedConsents[0]
      submit()
    },
  },
}
