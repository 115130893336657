import locale77427e78 from '.././gb-cms/locales/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","dateTimeFormats":{"en":{"short":{"year":"numeric","month":"short","day":"numeric"},"long":{"year":"numeric","month":"long","day":"numeric","weekday":"long","hour":"numeric","minute":"numeric"}},"hr":{"short":{"year":"numeric","month":"short","day":"numeric"},"long":{"year":"numeric","month":"long","day":"numeric","weekday":"long","hour":"numeric","minute":"numeric"}}}},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en","file":"en.js","name":"English"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/home/node/app/.nuxt/gb-cms/locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"locale","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":true,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: false,
  pages: {"page-product":{"en":"/product/:slug?","hr":"/product/:slug?","de":"/produkt/:slug?"},"page-login":{"en":"/login","hr":"/prijava","de":"/login"},"page-register":{"en":"/register","hr":"/registracija","de":"/register"},"page-profile":{"en":"/profile","hr":"/profil","de":"/profil"},"page-cart":{"en":"/cart","hr":"/kosarica","de":"/einkaufswagen"},"page-shop":{"en":"/shop/:slug?","hr":"/shop/:slug?","de":"/shop/:slug?"},"page-checkout":{"en":"/checkout","hr":"/naplata","de":"/kasse"},"page-checkout-success":{"en":"/checkout/success","hr":"/naplata/uspjesna","de":"/checkout/success"},"page-search":{"en":"/search","hr":"/pretraga","de":"/suche"},"page-post":{"en":"/:slug?","hr":"/:slug?","de":"/:slug?"},"page-blog":{"en":"/blog/:slug?","hr":"/objave/:slug?","de":"/blog/:slug?"},"page-forgot-password":{"en":"/forgot-password","hr":"/zaboravljena-lozinka","de":"/passwort-vergessen"},"page-social-login":{"en":"/social-login","hr":"/social-login","de":"/social-login"},"page-event":{"en":"/event/:id","hr":"/dogadjaj/:id","de":"/Veranstaltung/:id"},"page-confirm-email":{"en":"/confirm-email","hr":"/potvrdi-email","de":"/bestatigungs-email"}},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en","file":"en.js","name":"English"}],
  localeCodes: ["en"],
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
}
