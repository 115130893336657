import { render, staticRenderFns } from "./ProfileView.vue?vue&type=template&id=3cab4fdf&scoped=true"
import script from "./ProfileView.vue?vue&type=script&lang=js"
export * from "./ProfileView.vue?vue&type=script&lang=js"
import style0 from "./ProfileView.vue?vue&type=style&index=0&id=3cab4fdf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cab4fdf",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Btn: require('/home/node/app/components/ui/btn.vue').default,Check: require('/home/node/app/assets/icons/check.vue').default,UiInput: require('/home/node/app/components/ui/uiInput.vue').default,UiSelect: require('/home/node/app/components/ui/uiSelect.vue').default,ControllerUser: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerUser.js').default,ControllerChangePassword: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerChangePassword.js').default})
