import { render, staticRenderFns } from "./RegisterView.vue?vue&type=template&id=71b327d6"
import script from "./RegisterView.vue?vue&type=script&lang=js"
export * from "./RegisterView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiInput: require('/home/node/app/components/ui/uiInput.vue').default,Btn: require('/home/node/app/components/ui/btn.vue').default,ControllerRegister: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerRegister.js').default})
