import { render, staticRenderFns } from "./ForgotPasswordView.vue?vue&type=template&id=79ed2b3e"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiInput: require('/home/node/app/components/ui/uiInput.vue').default,Btn: require('/home/node/app/components/ui/btn.vue').default,ControllerForgotPassword: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerForgotPassword.js').default,ControllerResetPassword: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerResetPassword.js').default})
