
export default {
  name: 'UiInput',
  props: {
    type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
    inputClass: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    errorMessage: {
      type: [String, Boolean],
      default: '',
    },
    labelFor: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      visibility: this.type,
    }
  },
  methods: {
    showPassword() {
      this.visibility = 'text'
    },
    hidePassword() {
      this.visibility = 'password'
    },
  },
}
