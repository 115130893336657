
export default {
  name: 'Btn',
  props: {
    size: {
      type: String,
      required: false,
    },
    list: {
      type: Boolean,
      required: false,
    },
    bg: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    shadow: {
      type: Boolean,
      required: false,
    },
    link: {
      type: [String, Object],
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
}
