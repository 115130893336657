const math = {
  description: 'The maths exam contains content in line with the national curriculum, with candidates assessed on concepts taught up until the end of year 5.',
  performanceAnalysis: 'The content in the national curriculum is split into 6 main topics and your child’s performance in comparison to their peers is detailed below.',
  timeAnalysis: 'We recommend spending an approximately equal amount of time (1m 20s) on each question, regardless of the topic.',
  resultOrder: 0,
  numberOfQuestions: 30,
  numberOfTopics: 6,
  maxRank: 6,
  multiImageTextSection: null,
  variables: {
    average: '66',
    impact: '0.083',
    sd: '22',
  },
  order: {
    topics: 'random',
    questions: 'random',
  },
  startRank: 3,
  topics: {
    'Topic 1': {
      title: 'Number and Place Value',
      sku: 'Topic 1',
      icon: 'operations',
      grades: {
        5: { min: 17, max: 9999999 },
        4: { min: 11, max: 16 },
        3: { min: 7, max: 10 },
        2: { min: 4, max: 6 },
        1: { min: 0, max: 3 },
      },
      description: 'Place value, rounding and other number operations involving addition, subtraction, multiplication and division.',
    },
    'Topic 2': {
      title: 'Measurement',
      sku: 'Topic 2',
      icon: 'measurement',
      grades: {
        5: { min: 17, max: 9999999 },
        4: { min: 11, max: 16 },
        3: { min: 7, max: 10 },
        2: { min: 4, max: 6 },
        1: { min: 0, max: 3 },
      },
      description: 'Converting between different units, estimating quantity and calculating area /volume of shapes.',
    },
    'Topic 3': {
      title: 'Decimals and Percentages',
      sku: 'Topic 3',
      icon: 'percentages',
      grades: {
        5: { min: 17, max: 9999999 },
        4: { min: 11, max: 16 },
        3: { min: 7, max: 10 },
        2: { min: 4, max: 6 },
        1: { min: 0, max: 3 },
      },
      description: 'Solving problems involving percentages (including increasing/decreasing) and decimals up to thousandths.',
    },
    'Topic 4': {
      title: 'Fractions',
      sku: 'Topic 4',
      icon: 'fractions',
      grades: {
        5: { min: 17, max: 9999999 },
        4: { min: 11, max: 16 },
        3: { min: 7, max: 10 },
        2: { min: 4, max: 6 },
        1: { min: 0, max: 3 },
      },
      description: 'Converting between mixed & improper, ordering fractions and converting between fractions, percentages & decimals.',
    },
    'Topic 5': {
      title: 'Stats and Data',
      sku: 'Topic 5',
      icon: 'stats',
      grades: {
        5: { min: 17, max: 9999999 },
        4: { min: 11, max: 16 },
        3: { min: 7, max: 10 },
        2: { min: 4, max: 6 },
        1: { min: 0, max: 3 },
      },
      description: 'Interpreting and constructing tables, graphs and charts as well as calculating mean, median, mode and range.',
    },
    'Topic 6': {
      title: 'Geometry',
      sku: 'Topic 6',
      icon: 'geometry',
      grades: {
        5: { min: 17, max: 9999999 },
        4: { min: 11, max: 16 },
        3: { min: 7, max: 10 },
        2: { min: 4, max: 6 },
        1: { min: 0, max: 3 },
      },
      description: 'Identifying properties of shapes, angles, coordinate geometry and transformations such as reflections and rotations.',
    },
  },
}
const english = {
  description: 'The English exam contains content in line with the national curriculum, with candidates assessed on concepts taught up to Key Stage 2. ',
  performanceAnalysis: 'Each exam starts with a comprehension section followed by questions on spelling, punctuation and grammar. Your child’s performance in each of these areas in comparison to their peers is detailed below. ',
  timeAnalysis: 'We recommend spending slightly longer on the comprehension section as this requires careful reading of a text. The other sections should take approximately the same amount of time.',
  numberOfQuestions: 36,
  numberOfTopics: 5,
  maxRank: 5,
  multiImageTextSection: 'Comprehension',
  variables: {
    average: '60',
    impact: '0.083',
    sd: '20',
  },
  order: {
    topics: 'fixed',
    questions: 'random',
  },
  startRank: 3,
  resultOrder: 1,
  topics: {
    'Topic 1': {
      title: 'Comprehension',
      icon: 'comprehension',
      grades: {
        5: { min: 30, max: 9999999 },
        4: { min: 23, max: 29 },
        3: { min: 16, max: 22 },
        2: { min: 8, max: 15 },
        1: { min: 0, max: 7 },
      },
      description: 'Reading a piece of text and answering questions that test the understanding and interpretation of the excerpt.',
    },
    'Topic 2': {
      grades: {
        5: { min: 19, max: 9999999 },
        4: { min: 14, max: 18 },
        3: { min: 9, max: 13 },
        2: { min: 4, max: 8 },
        1: { min: 0, max: 3 },
      },
      title: 'Cloze sentences (Grammar)',
      icon: 'grammar',
      description: 'Completing sentences using different forms of words to ensure the grammar is correct.',
    },
    'Topic 3': {
      grades: {
        5: { min: 19, max: 9999999 },
        4: { min: 14, max: 18 },
        3: { min: 9, max: 13 },
        2: { min: 4, max: 8 },
        1: { min: 0, max: 3 },
      },
      title: 'Shuffled sentences',
      icon: 'spelling',
      description: 'Rearranging words to make a meaningful sentence and finding the word which does not fit.',
    },
    'Topic 4': {
      grades: {
        5: { min: 19, max: 9999999 },
        4: { min: 14, max: 18 },
        3: { min: 9, max: 13 },
        2: { min: 4, max: 8 },
        1: { min: 0, max: 3 },
      },
      title: 'Spelling',
      icon: 'spelling',
      description: 'Identifying spelling mistakes in various types of sentences, testing vocabulary and context.',
    },
    'Topic 5': {
      grades: {
        5: { min: 19, max: 9999999 },
        4: { min: 14, max: 18 },
        3: { min: 9, max: 13 },
        2: { min: 4, max: 8 },
        1: { min: 0, max: 3 },
      },
      title: 'Punctuation',
      icon: 'punctuation',
      description: 'Identifying punctuation mistakes in various types of sentences, testing rules of when to use different marks.',
    },
  },
}
const vr = {
  description: 'Verbal Reasoning tests the ability to think constructively and make use of some learned knowledge to solve problems. ',
  performanceAnalysis: 'Although there are many sections, these can be summarised into two main categories below. Your child’s performance in each of these skills in comparison to their peers is detailed below.',
  timeAnalysis: 'We recommend spending slightly longer on the Reasoning and Logic questions as these involve problems which can worked out with more thought. On the other hand, for Spelling and Vocabulary, the meaning of words is either known or not known so spending extra time will not help.      ',
  numberOfQuestions: 30,
  numberOfTopics: 6,
  maxRank: 4,
  multiImageTextSection: null,
  variables: {
    average: '54',
    impact: '0.083',
    sd: '17',
  },
  order: {
    topics: 'fixed',
    questions: 'random',
  },
  startRank: 2,
  resultOrder: 2,
  topics: {
    'Topic 1': {
      title: 'Reasoning and logic',
      icon: 'reasoning-logic',
      grades: {
        5: { min: 41, max: 9999999 },
        4: { min: 35, max: 40 },
        3: { min: 21, max: 34 },
        2: { min: 9, max: 20 },
        1: { min: 0, max: 8 },
      },
      description: 'These questions are designed to measure problem-solving abilities. This normally includes arriving at a conclusion based on a specific pattern, rule or principle. There is usually an optimal method in how to arrive at the correct solution, and repeated practise and familiarity can help to improve performance.',
    },
    'Topic 3': {
      title: 'Reasoning and logic',
      icon: 'reasoning-logic',
      grades: {
        5: { min: 41, max: 9999999 },
        4: { min: 35, max: 40 },
        3: { min: 21, max: 34 },
        2: { min: 9, max: 20 },
        1: { min: 0, max: 8 },
      },
      description: 'These questions are designed to measure problem-solving abilities. This normally includes arriving at a conclusion based on a specific pattern, rule or principle. There is usually an optimal method in how to arrive at the correct solution, and repeated practise and familiarity can help to improve performance.',
    },
    'Topic 5': {
      title: 'Reasoning and logic',
      icon: 'reasoning-logic',
      grades: {
        5: { min: 41, max: 9999999 },
        4: { min: 35, max: 40 },
        3: { min: 21, max: 34 },
        2: { min: 9, max: 20 },
        1: { min: 0, max: 8 },
      },
      description: 'These questions are designed to measure problem-solving abilities. This normally includes arriving at a conclusion based on a specific pattern, rule or principle. There is usually an optimal method in how to arrive at the correct solution, and repeated practise and familiarity can help to improve performance.',
    },
    'Topic 2': {
      title: 'Spelling and vocabulary',
      icon: 'spelling',
      grades: {
        5: { min: 41, max: 9999999 },
        4: { min: 35, max: 40 },
        3: { min: 21, max: 34 },
        2: { min: 9, max: 20 },
        1: { min: 0, max: 8 },
      },
      description: 'These questions test knowledge of the english language including vocabulary, spelling and grammar. Minimal reasoning is involved; if the meaning or spelling is not known, it is hard to arrive at the answer. Learning new words and reading a wide range of literature can help to improve performance in this section.',
    },
    'Topic 4': {
      title: 'Spelling and vocabulary',
      icon: 'spelling',
      grades: {
        5: { min: 41, max: 9999999 },
        4: { min: 35, max: 40 },
        3: { min: 21, max: 34 },
        2: { min: 9, max: 20 },
        1: { min: 0, max: 8 },
      },
      description: 'These questions test knowledge of the english language including vocabulary, spelling and grammar. Minimal reasoning is involved; if the meaning or spelling is not known, it is hard to arrive at the answer. Learning new words and reading a wide range of literature can help to improve performance in this section.',
    },
    'Topic 6': {
      title: 'Spelling and vocabulary',
      icon: 'spelling',
      grades: {
        5: { min: 41, max: 9999999 },
        4: { min: 35, max: 40 },
        3: { min: 21, max: 34 },
        2: { min: 9, max: 20 },
        1: { min: 0, max: 8 },
      },
      description: 'These questions test knowledge of the english language including vocabulary, spelling and grammar. Minimal reasoning is involved; if the meaning or spelling is not known, it is hard to arrive at the answer. Learning new words and reading a wide range of literature can help to improve performance in this section.',
    },
  },
}
const nvr = {
  description: 'Non-verbal reasoning tests the ability to understand and analyse visual information and solve problems using visual reasoning. ',
  performanceAnalysis: 'Although there are many sections, these can be summarised into three main categories below. ',
  timeAnalysis: 'We recommend spending an approximately equal amount of time for each question. However it is quite common for students to be far quicker in a particular area. ',
  numberOfQuestions: 36,
  numberOfTopics: 6,
  maxRank: 3,
  multiImageTextSection: null,
  order: {
    topics: 'fixed',
    questions: 'random',
  },
  variables: {
    average: '36',
    impact: '0.083',
    sd: '12',
  },
  startRank: 2,
  resultOrder: 3,
  topics: {
    'Topic 1': {
      title: 'Inductive reasoning',
      icon: 'inductive',
      grades: {
        5: { min: 23, max: 9999999 },
        4: { min: 18, max: 22 },
        3: { min: 11, max: 17 },
        2: { min: 5, max: 10 },
        1: { min: 0, max: 4 },
      },
      description: '<p>These questions test the ability to see patterns and consistencies in data and to extend this to other situations. Correctly solving them requires the candidate to make obversations and then reach a conclusion. </p><p>A real world example of inductive reasoning would be an architect seeing a pattern of high plumbing costs in previous projects and opting to increase their estimate for future proposals.</p>',
    },
    'Topic 4': {
      title: 'Inductive reasoning',
      icon: 'inductive',
      grades: {
        5: { min: 23, max: 9999999 },
        4: { min: 18, max: 22 },
        3: { min: 11, max: 17 },
        2: { min: 5, max: 10 },
        1: { min: 0, max: 4 },
      },
      description: '<p>These questions test the ability to see patterns and consistencies in data and to extend this to other situations. Correctly solving them requires the candidate to make obversations and then reach a conclusion. </p><p>A real world example of inductive reasoning would be an architect seeing a pattern of high plumbing costs in previous projects and opting to increase their estimate for future proposals.</p>',
    },
    'Topic 2': {
      title: 'Deductive reasoning',
      icon: 'reasoning',
      grades: {
        5: { min: 23, max: 9999999 },
        4: { min: 18, max: 22 },
        3: { min: 11, max: 17 },
        2: { min: 5, max: 10 },
        1: { min: 0, max: 4 },
      },
      description: '<p>These questions are designed to assess the ability to interpret patterns, number sequences and the relationships between shapes. To solve them, candidates need to start with a generalisation / theory and then test it by applying it to scenarios. </p><p>This type of reasoning is commonly used in science to come up with and then test a hypothesis. </p>',
    },
    'Topic 5': {
      title: 'Deductive reasoning',
      icon: 'reasoning',
      grades: {
        5: { min: 23, max: 9999999 },
        4: { min: 18, max: 22 },
        3: { min: 11, max: 17 },
        2: { min: 5, max: 10 },
        1: { min: 0, max: 4 },
      },
      description: '<p>These questions are designed to assess the ability to interpret patterns, number sequences and the relationships between shapes. To solve them, candidates need to start with a generalisation / theory and then test it by applying it to scenarios. </p><p>This type of reasoning is commonly used in science to come up with and then test a hypothesis. </p>',
    },
    'Topic 3': {
      title: 'Spatial reasoning',
      icon: 'spatial',
      grades: {
        5: { min: 23, max: 9999999 },
        4: { min: 18, max: 22 },
        3: { min: 11, max: 17 },
        2: { min: 5, max: 10 },
        1: { min: 0, max: 4 },
      },
      description: '<p>These questions test the ability to envisage interactions of 2D and 3D components. Candidates are required to visualise movements and spot patterns between shapes. </p><p>Spatial reasoning is commonly used by mathematicians to enhance quantity comparison, arithmetic, and number sense as well as having obvious implications in engineering and science.</p>',
    },
    'Topic 6': {
      title: 'Spatial reasoning',
      icon: 'spatial',
      grades: {
        5: { min: 23, max: 9999999 },
        4: { min: 18, max: 22 },
        3: { min: 11, max: 17 },
        2: { min: 5, max: 10 },
        1: { min: 0, max: 4 },
      },
      description: '<p>These questions test the ability to envisage interactions of 2D and 3D components. Candidates are required to visualise movements and spot patterns between shapes. </p><p>Spatial reasoning is commonly used by mathematicians to enhance quantity comparison, arithmetic, and number sense as well as having obvious implications in engineering and science.</p>',
    },
  },
}
export const state = () => ({
  orders: { data: [] },
  schools: [
    {
      schoolName: "Abbot's Hill School",
      schoolVal: 'abbot-s-hill-school',
    },
    // {
    //   schoolName: "Alleyn's School",
    //   schoolVal: 'alleyn-s-school',
    // },
    // {
    //   schoolName: 'Ampleforth College',
    //   schoolVal: 'ampleforth-college',
    // },
    {
      schoolName: 'Ardingly College',
      schoolVal: 'ardingly-college',
    },
    {
      schoolName: 'Bedford School',
      schoolVal: 'bedford-school',
    },
    {
      schoolName: 'Benenden School',
      schoolVal: 'benenden-school',
    },
    {
      schoolName: 'Berkhamsted Senior School',
      schoolVal: 'berkhamsted-senior-school',
    },
    {
      schoolName: 'Birkdale School',
      schoolVal: 'birkdale-school',
    },
    {
      schoolName: 'Bloxham School',
      schoolVal: 'bloxham-school',
    },
    {
      schoolName: 'Bradfield College',
      schoolVal: 'bradfield-college',
    },
    {
      schoolName: 'Brentwood School',
      schoolVal: 'brentwood-school',
    },
    {
      schoolName: 'Brighton College',
      schoolVal: 'brighton-college',
    },
    {
      schoolName: 'Bryanston School',
      schoolVal: 'bryanston-school',
    },
    {
      schoolName: 'Canford School',
      schoolVal: 'canford-school',
    },
    {
      schoolName: 'Caterham School',
      schoolVal: 'caterham-school',
    },
    // {
    //   schoolName: 'Channing School',
    //   schoolVal: 'channing-school',
    // },
    {
      schoolName: 'Charterhouse',
      schoolVal: 'charterhouse',
    },
    {
      schoolName: 'City of London School',
      schoolVal: 'city-of-london-school',
    },
    {
      schoolName: 'Cranleigh School',
      schoolVal: 'cranleigh-school',
    },
    {
      schoolName: 'Culford School',
      schoolVal: 'culford-school',
    },
    {
      schoolName: "Dauntsey's School",
      schoolVal: 'dauntsey-s-school',
    },
    {
      schoolName: 'Downe House School',
      schoolVal: 'downe-house-school',
    },
    {
      schoolName: 'Downside School',
      schoolVal: 'downside-school',
    },
    {
      schoolName: 'Dulwich College',
      schoolVal: 'dulwich-college',
    },
    {
      schoolName: 'Eaton Square Upper School',
      schoolVal: 'eaton-square-upper-school',
    },
    // {
    //   schoolName: 'Epsom College',
    //   schoolVal: 'epsom-college',
    // },
    {
      schoolName: 'Eton College',
      schoolVal: 'eton-college',
    },
    {
      schoolName: 'Felsted School',
      schoolVal: 'felsted-school',
    },
    // {
    //   schoolName: 'Francis Holland School',
    //   schoolVal: 'francis-holland-school',
    // },
    // {
    //   schoolName: 'Francis Holland School',
    //   schoolVal: 'francis-holland-school',
    // },
    // {
    //   schoolName: "Freemen's School",
    //   schoolVal: 'freemen-s-school',
    // },
    {
      schoolName: 'Fulham Senior School',
      schoolVal: 'fulham-senior-school',
    },
    {
      schoolName: 'Gateways School',
      schoolVal: 'gateways-school',
    },
    // {
    //   schoolName: 'Godolphin and Latymer School',
    //   schoolVal: 'godolphin-and-latymer-school',
    // },
    {
      schoolName: "Haberdashers' Monmouth Schools",
      schoolVal: 'haberdashers-monmouth-schools',
    },
    // {
    //   schoolName: 'Hampton School',
    //   schoolVal: 'hampton-school',
    // },
    // {
    //   schoolName: 'Haileybury School',
    //   schoolVal: 'haileybury-school',
    // },
    {
      schoolName: 'Harrodian Senior School',
      schoolVal: 'harrodian-senior-school',
    },
    {
      schoolName: 'Harrow School',
      schoolVal: 'harrow-school',
    },
    {
      schoolName: 'Headington School',
      schoolVal: 'headington-school',
    },
    {
      schoolName: 'Hoe Bridge School',
      schoolVal: 'hoe-bridge-school',
    },
    {
      schoolName: 'Holmwood House Senior School',
      schoolVal: 'holmwood-house-senior-school',
    },
    // {
    //   schoolName: 'Hull Collegiate School',
    //   schoolVal: 'hull-collegiate-school',
    // },
    {
      schoolName: 'Hurstpierpoint College',
      schoolVal: 'hurstpierpoint-college',
    },
    {
      schoolName: "James Allen's Girls' School",
      schoolVal: 'james-allen-s-girls-school',
    },
    {
      schoolName: 'Kew House School',
      schoolVal: 'kew-house-school',
    },
    {
      schoolName: "King Edward's School",
      schoolVal: 'king-edward-s-school',
    },
    // {
    //   schoolName: "Witley King's College",
    //   schoolVal: 'witley-king-s-college',
    // },
    {
      schoolName: "King's College",
      schoolVal: 'king-s-college',
    },
    {
      schoolName: "King's School",
      schoolVal: 'king-s-school',
    },
    {
      schoolName: "King's School Rochester",
      schoolVal: 'king-s-school-rochester',
    },
    {
      schoolName: 'Knightsbridge Senior School',
      schoolVal: 'knightsbridge-senior-school',
    },
    {
      schoolName: 'Lady Eleanor Holles',
      schoolVal: 'lady-eleanor-holles',
    },
    // {
    //   schoolName: 'Lancing College',
    //   schoolVal: 'lancing-college',
    // },
    // {
    //   schoolName: 'Latymer Upper School',
    //   schoolVal: 'latymer-upper-school',
    // },
    {
      schoolName: 'Leeds Grammar School',
      schoolVal: 'leeds-grammar-school',
    },
    {
      schoolName: 'Leys School',
      schoolVal: 'leys-school',
    },
    {
      schoolName: 'Lord Wandsworth College',
      schoolVal: 'lord-wandsworth-college',
    },
    {
      schoolName: 'Maida Vale School',
      schoolVal: 'maida-vale-school',
    },
    {
      schoolName: 'Marlborough College',
      schoolVal: 'marlborough-college',
    },
    {
      schoolName: 'Mayfield School',
      schoolVal: 'mayfield-school',
    },
    // {
    //   schoolName: "Merchant Taylors' Boys' School",
    //   schoolVal: 'merchant-taylors-boys-school',
    // },
    {
      schoolName: 'Mill Hill School',
      schoolVal: 'mill-hill-school',
    },
    // {
    //   schoolName: 'More House School',
    //   schoolVal: 'more-house-school',
    // },
    {
      schoolName: 'New Hall School',
      schoolVal: 'new-hall-school',
    },
    // {
    //   schoolName: 'Northwood College',
    //   schoolVal: 'northwood-college',
    // },
    {
      schoolName: 'Northwood Senior School',
      schoolVal: 'northwood-senior-school',
    },
    // {
    //   schoolName: 'Notting Hill and Ealing School',
    //   schoolVal: 'notting-hill-and-ealing-school',
    // },
    {
      schoolName: 'Oratory School',
      schoolVal: 'oratory-school',
    },
    {
      schoolName: 'Palmers Green High School ',
      schoolVal: 'palmers-green-high-school ',
    },
    {
      schoolName: 'Pangbourne College',
      schoolVal: 'pangbourne-college',
    },
    // {
    //   schoolName: 'Putney High School',
    //   schoolVal: 'putney-high-school',
    // },
    // {
    //   schoolName: "Queen's College",
    //   schoolVal: 'queen-s-college',
    // },
    // {
    //   schoolName: "Queen's Gate School",
    //   schoolVal: 'queen-s-gate-school',
    // },
    {
      schoolName: 'Radley College',
      schoolVal: 'radley-college',
    },
    {
      schoolName: "Reed's School",
      schoolVal: 'reed-s-school',
    },
    {
      schoolName: 'Royal Grammar School',
      schoolVal: 'royal-grammar-school',
    },
    // {
    //   schoolName: 'Royal Hospital School',
    //   schoolVal: 'royal-hospital-school',
    // },
    // {
    //   schoolName: 'Royal Russell School',
    //   schoolVal: 'royal-russell-school',
    // },
    {
      schoolName: 'Seaford College',
      schoolVal: 'seaford-college',
    },
    {
      schoolName: 'Sherborne Girls School',
      schoolVal: 'sherborne-girls-school',
    },
    {
      schoolName: 'Sherborne School',
      schoolVal: 'sherborne-school',
    },
    // {
    //   schoolName: 'South Hampstead High School',
    //   schoolVal: 'south-hampstead-high-school',
    // },
    {
      schoolName: 'St Albans High School',
      schoolVal: 'st-albans-high-school',
    },
    // {
    //   schoolName: "St Augustine's Priory School",
    //   schoolVal: 'st-augustine-s-priory-school',
    // },
    // {
    //   schoolName: "St Benedict's School",
    //   schoolVal: 'st-benedict-s-school',
    // },
    {
      schoolName: "St Dunstan's College",
      schoolVal: 'st-dunstan-s-college',
    },
    {
      schoolName: "St Edward's School",
      schoolVal: 'st-edward-s-school',
    },
    // {
    //   schoolName: "St Helen's School",
    //   schoolVal: 'st-helen-s-school',
    // },
    // {
    //   schoolName: "St James Senior Girls' School",
    //   schoolVal: 'st-james-senior-girls-school',
    // },
    // {
    //   schoolName: "St Margaret's School",
    //   schoolVal: 'st-margaret-s-school',
    // },
    {
      schoolName: "St Paul's School",
      schoolVal: 'st-pauls',
    },
    {
      schoolName: "St Swithun's School",
      schoolVal: 'st-swithun-s-school',
    },
    {
      schoolName: 'Stowe School',
      schoolVal: 'stowe-school',
    },
    {
      schoolName: 'Streatham & Clapham High School',
      schoolVal: 'streatham-clapham-high-school',
    },
    {
      schoolName: 'Sutton Valence School',
      schoolVal: 'sutton-valence-school',
    },
    // {
    //   schoolName: "Thomas's Battersea Square",
    //   schoolVal: 'thomas-s-battersea-square',
    // },
    {
      schoolName: 'Tonbridge School',
      schoolVal: 'tonbridge-school',
    },
    {
      schoolName: 'Tormead School',
      schoolVal: 'tormead-school',
    },
    {
      schoolName: 'Tranby Senior  School',
      schoolVal: 'tranby-senior-school',
    },
    {
      schoolName: 'Trinity School',
      schoolVal: 'trinity-school',
    },
    // {
    //   schoolName: 'Uppingham School',
    //   schoolVal: 'uppingham-school',
    // },
    {
      schoolName: 'Walthamstow Hall School',
      schoolVal: 'walthamstow-hall-school',
    },
    {
      schoolName: 'Wellington College',
      schoolVal: 'wellington-college',
    },
    {
      schoolName: 'Westminster School',
      schoolVal: 'westminster-school',
    },
    {
      schoolName: 'Wetherby Senior School',
      schoolVal: 'wetherby-senior-school',
    },
    // {
    //   schoolName: 'Whitgift School',
    //   schoolVal: 'whitgift-school',
    // },
    // {
    //   schoolName: 'Wimbledon High School',
    //   schoolVal: 'wimbledon-high-school',
    // },
    {
      schoolName: 'Winchester College',
      schoolVal: 'winchester-college',
    },
    {
      schoolName: 'Woldingham School',
      schoolVal: 'woldingham-school',
    },
    {
      schoolName: 'Worth School',
      schoolVal: 'worth-school',
    },
  ],
  examContent: {
    Maths: math,
    Math: math,
    English: english,
    'Verbal Reasoning': vr,
    'Non-verbal Reasoning': nvr,
    'Non-Verbal Reasoning': nvr,
    NVR: nvr,
  },
})

export const mutations = {
  SET_ORDERS(state, list) {
    state.orders = list
  },
}

export const actions = {
  async nuxtServerInit({ commit, dispatch }) {
    await dispatch('setOrders')
  },
  // async setOrders({ commit }) {
  //   if (this.$auth.loggedIn) {
  //     try {
  //       const data = (await this.$axios.get('api/v1/document/purchasedProducts')).data.data
  //       if (data) {
  //         commit('SET_ORDERS', data)
  //       }
  //     } catch (e) {
  //       commit('SET_ORDERS', { data: [] })
  //     }
  //   } else {
  //     commit('SET_ORDERS', { data: [] })
  //   }
  // },
  async setOrders({ commit }) {
    if (this.$auth.loggedIn) {
      try {
        const products = []
        const body = {
          filters: [
            {
              key: 'store',
              value: this.$application.store?.storeID || 0,
            },
            { key: 'paymentStatus', value: 'paid' },
          ],
          limit: 10000,
        }
        const data = (await this.$axios.post('api/v1/smartDocument/document/filter/web', body)).data.data
        data.records.forEach((el) => {
          if (el.items && el.items.length) {
            el.items.forEach((item) => {
              products.push(item.item)
            })
          }
        })
        if (data) {
          commit('SET_ORDERS', products)
        }
      } catch (e) {
        commit('SET_ORDERS', { data: [] })
      }
    } else {
      commit('SET_ORDERS', { data: [] })
    }
  },
}
