export const ControllerBundleCustomization = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerBundleCustomization.js' /* webpackChunkName: "components/controller-bundle-customization" */).then(c => wrapFunctional(c.default || c))
export const ControllerBundles = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerBundles.js' /* webpackChunkName: "components/controller-bundles" */).then(c => wrapFunctional(c.default || c))
export const ControllerCalendar = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCalendar.js' /* webpackChunkName: "components/controller-calendar" */).then(c => wrapFunctional(c.default || c))
export const ControllerCart = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCart.js' /* webpackChunkName: "components/controller-cart" */).then(c => wrapFunctional(c.default || c))
export const ControllerCategory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCategory.js' /* webpackChunkName: "components/controller-category" */).then(c => wrapFunctional(c.default || c))
export const ControllerCommentList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCommentList.js' /* webpackChunkName: "components/controller-comment-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerConfirmEmail = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerConfirmEmail.js' /* webpackChunkName: "components/controller-confirm-email" */).then(c => wrapFunctional(c.default || c))
export const ControllerContactForm = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerContactForm.js' /* webpackChunkName: "components/controller-contact-form" */).then(c => wrapFunctional(c.default || c))
export const ControllerCookie = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCookie.js' /* webpackChunkName: "components/controller-cookie" */).then(c => wrapFunctional(c.default || c))
export const ControllerCrossSell = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCrossSell.js' /* webpackChunkName: "components/controller-cross-sell" */).then(c => wrapFunctional(c.default || c))
export const ControllerCustomForm = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCustomForm.js' /* webpackChunkName: "components/controller-custom-form" */).then(c => wrapFunctional(c.default || c))
export const ControllerError = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerError.js' /* webpackChunkName: "components/controller-error" */).then(c => wrapFunctional(c.default || c))
export const ControllerEvent = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerEvent.js' /* webpackChunkName: "components/controller-event" */).then(c => wrapFunctional(c.default || c))
export const ControllerFeaturedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerFeaturedProducts.js' /* webpackChunkName: "components/controller-featured-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerFooter = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerFooter.js' /* webpackChunkName: "components/controller-footer" */).then(c => wrapFunctional(c.default || c))
export const ControllerHeader = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerHeader.js' /* webpackChunkName: "components/controller-header" */).then(c => wrapFunctional(c.default || c))
export const ControllerLayeredNavigation = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerLayeredNavigation.js' /* webpackChunkName: "components/controller-layered-navigation" */).then(c => wrapFunctional(c.default || c))
export const ControllerNavigationBar = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationBar.js' /* webpackChunkName: "components/controller-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const ControllerNavigationItem = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationItem.js' /* webpackChunkName: "components/controller-navigation-item" */).then(c => wrapFunctional(c.default || c))
export const ControllerNewsletter = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNewsletter.js' /* webpackChunkName: "components/controller-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ControllerPost = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPost.js' /* webpackChunkName: "components/controller-post" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostCategory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategory.js' /* webpackChunkName: "components/controller-post-category" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostCategoryList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategoryList.js' /* webpackChunkName: "components/controller-post-category-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostList.js' /* webpackChunkName: "components/controller-post-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostReview = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostReview.js' /* webpackChunkName: "components/controller-post-review" */).then(c => wrapFunctional(c.default || c))
export const ControllerProduct = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProduct.js' /* webpackChunkName: "components/controller-product" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductCategories = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductCategories.js' /* webpackChunkName: "components/controller-product-categories" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductConfiguration = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductConfiguration.js' /* webpackChunkName: "components/controller-product-configuration" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductCustomOptions = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductCustomOptions.js' /* webpackChunkName: "components/controller-product-custom-options" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductList.js' /* webpackChunkName: "components/controller-product-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductListContainer = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductListContainer.js' /* webpackChunkName: "components/controller-product-list-container" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductsListFiltered = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductsListFiltered.js' /* webpackChunkName: "components/controller-products-list-filtered" */).then(c => wrapFunctional(c.default || c))
export const ControllerPublicUpload = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPublicUpload.js' /* webpackChunkName: "components/controller-public-upload" */).then(c => wrapFunctional(c.default || c))
export const ControllerRecentlyViewedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerRecentlyViewedProducts.js' /* webpackChunkName: "components/controller-recently-viewed-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerRelatedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerRelatedProducts.js' /* webpackChunkName: "components/controller-related-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerSearch = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerSearch.js' /* webpackChunkName: "components/controller-search" */).then(c => wrapFunctional(c.default || c))
export const ControllerUpSell = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerUpSell.js' /* webpackChunkName: "components/controller-up-sell" */).then(c => wrapFunctional(c.default || c))
export const ControllerUserComment = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerUserComment.js' /* webpackChunkName: "components/controller-user-comment" */).then(c => wrapFunctional(c.default || c))
export const ControllerAddress = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerAddress.js' /* webpackChunkName: "components/controller-address" */).then(c => wrapFunctional(c.default || c))
export const ControllerPayment = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerPayment.js' /* webpackChunkName: "components/controller-payment" */).then(c => wrapFunctional(c.default || c))
export const ControllerShipping = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerShipping.js' /* webpackChunkName: "components/controller-shipping" */).then(c => wrapFunctional(c.default || c))
export const ControllerChangePassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerChangePassword.js' /* webpackChunkName: "components/controller-change-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerForgotPassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerForgotPassword.js' /* webpackChunkName: "components/controller-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerLogin = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerLogin.js' /* webpackChunkName: "components/controller-login" */).then(c => wrapFunctional(c.default || c))
export const ControllerOrderHistory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerOrderHistory.js' /* webpackChunkName: "components/controller-order-history" */).then(c => wrapFunctional(c.default || c))
export const ControllerRegister = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerRegister.js' /* webpackChunkName: "components/controller-register" */).then(c => wrapFunctional(c.default || c))
export const ControllerResetPassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerResetPassword.js' /* webpackChunkName: "components/controller-reset-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerSocialLogin = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLogin.js' /* webpackChunkName: "components/controller-social-login" */).then(c => wrapFunctional(c.default || c))
export const ControllerSocialLoginButtons = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLoginButtons.js' /* webpackChunkName: "components/controller-social-login-buttons" */).then(c => wrapFunctional(c.default || c))
export const ControllerUser = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerUser.js' /* webpackChunkName: "components/controller-user" */).then(c => wrapFunctional(c.default || c))
export const ControllerWishlist = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerWishlist.js' /* webpackChunkName: "components/controller-wishlist" */).then(c => wrapFunctional(c.default || c))
export const EditorIcon = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorIcon.js' /* webpackChunkName: "components/editor-icon" */).then(c => wrapFunctional(c.default || c))
export const EditorImage = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js' /* webpackChunkName: "components/editor-image" */).then(c => wrapFunctional(c.default || c))
export const EditorImageMarkers = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorImageMarkers.js' /* webpackChunkName: "components/editor-image-markers" */).then(c => wrapFunctional(c.default || c))
export const EditorInstagramPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorInstagramPostList.js' /* webpackChunkName: "components/editor-instagram-post-list" */).then(c => wrapFunctional(c.default || c))
export const EditorLink = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorLink.js' /* webpackChunkName: "components/editor-link" */).then(c => wrapFunctional(c.default || c))
export const EditorList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorList.js' /* webpackChunkName: "components/editor-list" */).then(c => wrapFunctional(c.default || c))
export const EditorListItem = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorListItem.js' /* webpackChunkName: "components/editor-list-item" */).then(c => wrapFunctional(c.default || c))
export const EditorMap = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorMap.js' /* webpackChunkName: "components/editor-map" */).then(c => wrapFunctional(c.default || c))
export const EditorPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorPostList.js' /* webpackChunkName: "components/editor-post-list" */).then(c => wrapFunctional(c.default || c))
export const EditorProductCategoryList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorProductCategoryList.js' /* webpackChunkName: "components/editor-product-category-list" */).then(c => wrapFunctional(c.default || c))
export const EditorProductList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorProductList.js' /* webpackChunkName: "components/editor-product-list" */).then(c => wrapFunctional(c.default || c))
export const EditorTipTap = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js' /* webpackChunkName: "components/editor-tip-tap" */).then(c => wrapFunctional(c.default || c))
export const BaseTimer = () => import('../../components/ui/BaseTimer.vue' /* webpackChunkName: "components/base-timer" */).then(c => wrapFunctional(c.default || c))
export const Btn = () => import('../../components/ui/btn.vue' /* webpackChunkName: "components/btn" */).then(c => wrapFunctional(c.default || c))
export const BtnOrange = () => import('../../components/ui/btnOrange.vue' /* webpackChunkName: "components/btn-orange" */).then(c => wrapFunctional(c.default || c))
export const FormModal = () => import('../../components/ui/formModal.vue' /* webpackChunkName: "components/form-modal" */).then(c => wrapFunctional(c.default || c))
export const Packages = () => import('../../components/ui/packages.vue' /* webpackChunkName: "components/packages" */).then(c => wrapFunctional(c.default || c))
export const UiBox = () => import('../../components/ui/uiBox.vue' /* webpackChunkName: "components/ui-box" */).then(c => wrapFunctional(c.default || c))
export const UiCheckbox = () => import('../../components/ui/uiCheckbox.vue' /* webpackChunkName: "components/ui-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiClass = () => import('../../components/ui/uiClass.vue' /* webpackChunkName: "components/ui-class" */).then(c => wrapFunctional(c.default || c))
export const UiInput = () => import('../../components/ui/uiInput.vue' /* webpackChunkName: "components/ui-input" */).then(c => wrapFunctional(c.default || c))
export const UiPost = () => import('../../components/ui/uiPost.vue' /* webpackChunkName: "components/ui-post" */).then(c => wrapFunctional(c.default || c))
export const UiProduct = () => import('../../components/ui/uiProduct.vue' /* webpackChunkName: "components/ui-product" */).then(c => wrapFunctional(c.default || c))
export const UiRadio = () => import('../../components/ui/uiRadio.vue' /* webpackChunkName: "components/ui-radio" */).then(c => wrapFunctional(c.default || c))
export const UiSelect = () => import('../../components/ui/uiSelect.vue' /* webpackChunkName: "components/ui-select" */).then(c => wrapFunctional(c.default || c))
export const UiTextarea = () => import('../../components/ui/uiTextarea.vue' /* webpackChunkName: "components/ui-textarea" */).then(c => wrapFunctional(c.default || c))
export const Vueslider = () => import('../../components/ui/vueslider.vue' /* webpackChunkName: "components/vueslider" */).then(c => wrapFunctional(c.default || c))
export const Arrow = () => import('../../assets/icons/arrow.vue' /* webpackChunkName: "components/arrow" */).then(c => wrapFunctional(c.default || c))
export const Back = () => import('../../assets/icons/back.vue' /* webpackChunkName: "components/back" */).then(c => wrapFunctional(c.default || c))
export const BottomLeftLine = () => import('../../assets/icons/bottom-left-line.vue' /* webpackChunkName: "components/bottom-left-line" */).then(c => wrapFunctional(c.default || c))
export const BottomRightLine = () => import('../../assets/icons/bottom-right-line.vue' /* webpackChunkName: "components/bottom-right-line" */).then(c => wrapFunctional(c.default || c))
export const Caret = () => import('../../assets/icons/caret.vue' /* webpackChunkName: "components/caret" */).then(c => wrapFunctional(c.default || c))
export const Check = () => import('../../assets/icons/check.vue' /* webpackChunkName: "components/check" */).then(c => wrapFunctional(c.default || c))
export const Close = () => import('../../assets/icons/close.vue' /* webpackChunkName: "components/close" */).then(c => wrapFunctional(c.default || c))
export const Comprehension = () => import('../../assets/icons/comprehension.vue' /* webpackChunkName: "components/comprehension" */).then(c => wrapFunctional(c.default || c))
export const Copy = () => import('../../assets/icons/copy.vue' /* webpackChunkName: "components/copy" */).then(c => wrapFunctional(c.default || c))
export const Drop = () => import('../../assets/icons/drop.vue' /* webpackChunkName: "components/drop" */).then(c => wrapFunctional(c.default || c))
export const Edit = () => import('../../assets/icons/edit.vue' /* webpackChunkName: "components/edit" */).then(c => wrapFunctional(c.default || c))
export const Envelope = () => import('../../assets/icons/envelope.vue' /* webpackChunkName: "components/envelope" */).then(c => wrapFunctional(c.default || c))
export const EyeSec = () => import('../../assets/icons/eye-sec.vue' /* webpackChunkName: "components/eye-sec" */).then(c => wrapFunctional(c.default || c))
export const Eye = () => import('../../assets/icons/eye.vue' /* webpackChunkName: "components/eye" */).then(c => wrapFunctional(c.default || c))
export const Facebook = () => import('../../assets/icons/facebook.vue' /* webpackChunkName: "components/facebook" */).then(c => wrapFunctional(c.default || c))
export const Fractions = () => import('../../assets/icons/fractions.vue' /* webpackChunkName: "components/fractions" */).then(c => wrapFunctional(c.default || c))
export const Gear = () => import('../../assets/icons/gear.vue' /* webpackChunkName: "components/gear" */).then(c => wrapFunctional(c.default || c))
export const Geometry = () => import('../../assets/icons/geometry.vue' /* webpackChunkName: "components/geometry" */).then(c => wrapFunctional(c.default || c))
export const Grammar = () => import('../../assets/icons/grammar.vue' /* webpackChunkName: "components/grammar" */).then(c => wrapFunctional(c.default || c))
export const Inductive = () => import('../../assets/icons/inductive.vue' /* webpackChunkName: "components/inductive" */).then(c => wrapFunctional(c.default || c))
export const Info = () => import('../../assets/icons/info.vue' /* webpackChunkName: "components/info" */).then(c => wrapFunctional(c.default || c))
export const Instagram = () => import('../../assets/icons/instagram.vue' /* webpackChunkName: "components/instagram" */).then(c => wrapFunctional(c.default || c))
export const LeftArrow = () => import('../../assets/icons/left-arrow.vue' /* webpackChunkName: "components/left-arrow" */).then(c => wrapFunctional(c.default || c))
export const Line2 = () => import('../../assets/icons/line2.vue' /* webpackChunkName: "components/line2" */).then(c => wrapFunctional(c.default || c))
export const Mail = () => import('../../assets/icons/mail.vue' /* webpackChunkName: "components/mail" */).then(c => wrapFunctional(c.default || c))
export const Measurement = () => import('../../assets/icons/measurement.vue' /* webpackChunkName: "components/measurement" */).then(c => wrapFunctional(c.default || c))
export const Operations = () => import('../../assets/icons/operations.vue' /* webpackChunkName: "components/operations" */).then(c => wrapFunctional(c.default || c))
export const Percentage = () => import('../../assets/icons/percentage.vue' /* webpackChunkName: "components/percentage" */).then(c => wrapFunctional(c.default || c))
export const Percentages = () => import('../../assets/icons/percentages.vue' /* webpackChunkName: "components/percentages" */).then(c => wrapFunctional(c.default || c))
export const Profile = () => import('../../assets/icons/profile.vue' /* webpackChunkName: "components/profile" */).then(c => wrapFunctional(c.default || c))
export const Punctuation = () => import('../../assets/icons/punctuation.vue' /* webpackChunkName: "components/punctuation" */).then(c => wrapFunctional(c.default || c))
export const ReasoningLogic = () => import('../../assets/icons/reasoning-logic.vue' /* webpackChunkName: "components/reasoning-logic" */).then(c => wrapFunctional(c.default || c))
export const Reasoning = () => import('../../assets/icons/reasoning.vue' /* webpackChunkName: "components/reasoning" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../assets/icons/search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const Spatial = () => import('../../assets/icons/spatial.vue' /* webpackChunkName: "components/spatial" */).then(c => wrapFunctional(c.default || c))
export const Speach = () => import('../../assets/icons/speach.vue' /* webpackChunkName: "components/speach" */).then(c => wrapFunctional(c.default || c))
export const Spelling = () => import('../../assets/icons/spelling.vue' /* webpackChunkName: "components/spelling" */).then(c => wrapFunctional(c.default || c))
export const StarFull = () => import('../../assets/icons/star-full.vue' /* webpackChunkName: "components/star-full" */).then(c => wrapFunctional(c.default || c))
export const Star = () => import('../../assets/icons/star.vue' /* webpackChunkName: "components/star" */).then(c => wrapFunctional(c.default || c))
export const Stats = () => import('../../assets/icons/stats.vue' /* webpackChunkName: "components/stats" */).then(c => wrapFunctional(c.default || c))
export const TopLeftLine = () => import('../../assets/icons/top-left-line.vue' /* webpackChunkName: "components/top-left-line" */).then(c => wrapFunctional(c.default || c))
export const TopRightLine = () => import('../../assets/icons/top-right-line.vue' /* webpackChunkName: "components/top-right-line" */).then(c => wrapFunctional(c.default || c))
export const Twitter = () => import('../../assets/icons/twitter.vue' /* webpackChunkName: "components/twitter" */).then(c => wrapFunctional(c.default || c))
export const Whatsapp = () => import('../../assets/icons/whatsapp.vue' /* webpackChunkName: "components/whatsapp" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
