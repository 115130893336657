
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      userProductsIds: [],
      login: false,
      showAddress: false,
      showCouponForm: false,
      register: false,
      loopProduct: {},
      addToCartProduct: {},
      intro: true,
      liveConsultation: false,
    }
  },
  watch: {
    addToCartProduct() {
      this.$cart.addItem(this.addToCartProduct, 1)
    },
    register() {
      this.sendDummyAddress()
    },
  },
  async mounted() {
    await this.$nextTick()
    this.skipAddress()
  },
  computed: {
    ...mapState(['orders']),
  },
  beforeDestroy() {
    document.body.style.overflow = ''
  },

  methods: {
    ...mapActions(['setOrders']),
    checkConsultation(product) {
      let liveConsultation = false
      if (product.sku.startsWith('live-consultations')) {
        this.liveConsultation = true
        liveConsultation = true
      }
      return liveConsultation
    },
    skipAddress() {
      setTimeout(() => {
        this.$refs.submitAddress.$el.click()
      }, 1500)
    },
    sendDummyAddress() {
      setTimeout(() => {
        const registerForm = this.$refs.register.form.deliveryForm
        registerForm.city = 'city'
        registerForm.country = 'GB'
        registerForm.houseNumber = '1'
        registerForm.street = 'street'
        registerForm.zipCode = '12345'
      }, 1000)
    },
  },
}
