
export default {
  data() {
    return {
      modalOpen: false,
    }
  },
  //   beforeMount() {
  //     const modal = this.$cookies.get('modalSet')
  //     if (modal && modal === true) {
  //       return false
  //     }
  //     this.modalOpen = true
  //   },
  methods: {
    closeModal() {
      this.$cookies.set('modalSet', 'true', 365)
      this.modalOpen = false
    },
  },
}
